import React, { useState, useEffect } from 'react';
import { FaRegFileAlt } from "react-icons/fa";
import ModalCargaCSV from './ModalCargaCSV';

function Analizador() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  // Cargar el historial de archivos al montar el componente
  useEffect(() => {
    const files = JSON.parse(localStorage.getItem('uploadedFiles')) || [];
    setUploadedFiles(files);
  }, [isModalOpen]); // Se recarga cada vez que se cierra el modal (después de cargar un archivo)

  // Función para analizar el archivo (aquí puedes agregar la lógica para redirigir a la URL correspondiente)
  const handleAnalyze = (fileId) => {
    window.location.href = `https://analizador.saptibank.vulcanics.mx/analisisingresos?registro_id=${fileId}`;
  };

  return (
    <div className="p-5 w-full">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-saptibank-blue">Estados de cuenta</h1>
        <button 
          onClick={() => setIsModalOpen(true)}
          className="bg-saptibank-blue text-white py-2 px-4 rounded-lg flex items-center hover:bg-saptibank-blue transition duration-300 text-xl"
        >
          <FaRegFileAlt className='mr-3' />
          Cargar CSV
        </button>
      </div>

      {/* Tabla de archivos */}
      <div className="overflow-x-auto rounded-lg">
        <div className="overflow-hidden rounded-lg border border-gray-300">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-3 px-6 text-lg font-bold text-center text-slate-400 bg-gray-100">Nombre del Archivo</th>
                <th className="py-3 px-6 text-lg font-bold text-center text-slate-400 bg-gray-100">Fecha de Carga</th>
                <th className="py-3 px-6 text-lg font-bold text-center text-slate-400 bg-gray-100">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {uploadedFiles.length > 0 ? (
                uploadedFiles.map((file) => (
                  <tr key={file.id}>
                    <td className="py-4 px-6 text-center">{file.name}</td>
                    <td className="py-4 px-6 text-center">{file.date}</td>
                    <td className="py-4 px-6 text-center">
                      <button 
                        onClick={() => handleAnalyze(file.id)}
                        className="bg-saptibank-blue text-white px-4 py-2 rounded-lg hover:bg-saptibank-darkblue"
                      >
                        Analizar
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="py-4 px-6 text-slate-300 text-center" colSpan="3">No hay archivos cargados</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal para la carga de CSV */}
      <ModalCargaCSV isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
}

export default Analizador;
