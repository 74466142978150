// export const saptiva = async (userMessage) => {
// const response = await fetch('https://bot.saptibank.vulcanics.mx/query', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       userMessage: userMessage
//     })
//   });

//   if (response.ok) {
//     const data = await response.json();
//     return data.response;  // Ajusta esto según la estructura real de la respuesta de la API
//   } else {
//     const responseError = response.json()
//     console.log(responseError)
//     console.error('Error al obtener la respuesta del bot', response.statusText);
//     return "Hubo un problema al obtener la respuesta del bot.";
//   }
// };
export const saptiva = async (userMessage) => {
  const response = await fetch('https://bot.saptibank.vulcanics.mx/query', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userMessage: userMessage
      })
    });
  
    if (response.ok) {
      const data = await response.json();
      return data;  // Ajusta esto según la estructura real de la respuesta de la API
    } else {
      const responseError = response.json()
      console.log(responseError)
      console.error('Error al obtener la respuesta del bot', response.statusText);
      return "Hubo un problema al obtener la respuesta del bot.";
    }
  };
  