import React from 'react';

const ResultsModalCFDI = ({ isOpen, onClose, cfdiData }) => {
  if (!isOpen || !cfdiData) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-xl max-h-[80vh] overflow-y-auto">
        <h2 className="text-2xl font-bold text-blue-600 mb-4">Resultados de la Consulta CFDI</h2>
        <p className="text-gray-500 mb-6">Se encontraron los siguientes resultados:</p>

        <ul className="space-y-4">
          <li><strong>Efecto del Comprobante:</strong> {cfdiData.efecto_comprobante}</li>
          <li><strong>Estado del CFDI:</strong> {cfdiData.estado_cfdi}</li>
          <li><strong>Estatus de Cancelación:</strong> {cfdiData.estatus_cancelacion}</li>
          <li><strong>Fecha de Cancelación:</strong> {cfdiData.fecha_cancelacion}</li>
          <li><strong>Fecha de Certificación:</strong> {cfdiData.fecha_certificacion}</li>
          <li><strong>Fecha de Expedición:</strong> {cfdiData.fecha_expedicion}</li>
          <li><strong>Folio Fiscal:</strong> {cfdiData.folio_fiscal}</li>
          <li><strong>Folio Sustitución:</strong> {cfdiData.folio_sustitucion}</li>
          <li><strong>Motivo de Cancelación:</strong> {cfdiData.motivo_cancelacion}</li>
          <li><strong>Nombre del Emisor:</strong> {cfdiData.nombre_emisor}</li>
          <li><strong>Nombre del Receptor:</strong> {cfdiData.nombre_receptor}</li>
          <li><strong>PAC Certificador:</strong> {cfdiData.pac_certifico}</li>
          <li><strong>RFC del Emisor:</strong> {cfdiData.rfc_emisor}</li>
          <li><strong>RFC del Receptor:</strong> {cfdiData.rfc_receptor}</li>
          <li><strong>Total CFDI:</strong> {cfdiData.total_cfdi}</li>
        </ul>

        <button 
          onClick={onClose} 
          className="mt-6 bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default ResultsModalCFDI;
