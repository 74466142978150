import React, { useState, useRef } from "react";
import { FaRegFileAlt } from "react-icons/fa";
import { uploadCSV } from '../api/analizador'; 
import Swal from 'sweetalert2';

const ModalCargaCSV = ({ isOpen, onClose }) => {
  const [isLoading, setIsLoading] = useState(false); 
  const [fileName, setFileName] = useState(null); 
  const fileInputRef = useRef(null); 
  if (!isOpen) return null;

  // Función para generar un identificador único basado en Timestamp y un valor aleatorio
  const generateUniqueID = () => `${Date.now()}-${Math.floor(Math.random() * 10000)}`;

const handleFileChange = async (event) => {
  const file = event.target.files[0];
  if (file) {
    if (!file.name.endsWith('.csv')) {
      Swal.fire({
        icon: 'error',
        title: 'Archivo no permitido',
        text: 'Solo se permiten archivos con extensión .csv.',
        confirmButtonText: 'Aceptar'
      });
      return;
    }
    
    const uniqueID = generateUniqueID();
    setFileName(file.name); 
    setIsLoading(true); 
  
    // Configuración de reintento
    const maxRetries = 3; 
    let attempts = 0;
    let success = false;

    while (attempts < maxRetries && !success) {
      try {
        const response = await uploadCSV(uniqueID, file);
        if (response) {
          console.log('Documento cargado exitosamente a la API con uniqueID:', uniqueID); 
          setIsLoading(false);
          success = true;

          // Guardar el archivo en localStorage
          const existingFiles = JSON.parse(localStorage.getItem('uploadedFiles')) || [];
          const newFile = { id: uniqueID, name: file.name, date: new Date().toLocaleString() };
          existingFiles.push(newFile);
          localStorage.setItem('uploadedFiles', JSON.stringify(existingFiles));

          window.location.href = `https://analizador.saptibank.vulcanics.mx/analisisingresos?registro_id=${uniqueID}`;
        }
      } catch (error) {
        console.error(`Intento ${attempts + 1} fallido al cargar el archivo:`, error);
        attempts += 1;
        if (attempts < maxRetries) {
          await new Promise(resolve => setTimeout(resolve, 2000));
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error en el procesamiento',
            text: 'El documento no pudo ser procesado. Inténtalo nuevamente.',
            confirmButtonText: 'Aceptar'
          });
        }
      }
    }

    setFileName(null);
    setIsLoading(false);
  }
};

  

  // Función para manejar el arrastre y selección del archivo
  const handleDrop = async (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      if (!file.name.endsWith('.csv')) {
        Swal.fire({
          icon: 'error',
          title: 'Archivo no permitido',
          text: 'Solo se permiten archivos con extensión .csv.',
          confirmButtonText: 'Aceptar'
        });
        return;
      }

      const uniqueID = generateUniqueID();
      setFileName(file.name);
      setIsLoading(true);

      try {
        const response = await uploadCSV(uniqueID, file);
        if (response) {
          console.log('Documento cargado exitosamente a la API con uniqueID:', uniqueID);
          setIsLoading(false);
          window.location.href = `https://analizador.saptibank.vulcanics.mx/analisisingresos?registro_id=${uniqueID}`;
        }
      } catch (error) {
        console.error('Error al cargar el archivo:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error en el procesamiento',
          text: 'El documento no pudo ser procesado. Inténtalo nuevamente.',
          confirmButtonText: 'Aceptar'
        });
      } finally {
        setFileName(null); // Limpiar el nombre del archivo
        setIsLoading(false);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Función para abrir el diálogo de selección de archivo al hacer clic
  const handleClick = () => {
    fileInputRef.current.click(); 
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-2xl">
        <h2 className="text-2xl font-bold text-saptibank-blue mb-4">
          Carga de Estados de cuenta
        </h2>
        <p className="text-gray-600 mb-6">
          Envía de manera directa un estado de cuenta en formato CSV a ser
          analizado.
        </p>

        {/* Ejemplo de archivo de formato */}
        <div className="border border-gray-300 p-4 rounded-lg flex justify-between items-center mb-6">
          <div className="flex items-center">
            <FaRegFileAlt
              className="mr-2 text-gray-500"
              style={{ fontSize: "40px" }}
            />
            <div className="flex flex-col">
              <p className="text-lg font-bold">Ejemplo Formato.CSV</p>
              <p className="text-xs text-gray-500">887 Bytes</p>
            </div>
          </div>

          <a
            href="../assets/document/analisisDelIngresosEjemplo.csv"
            download
            className="bg-saptibank-blue text-white px-4 py-2 rounded-lg"
          >
            Descargar
          </a>
        </div>

        {/* Zona de carga de archivo por arrastre o clic */}
        <div
          className="border-2 border-dashed border-gray-300 p-6 rounded-lg text-center cursor-pointer"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onClick={handleClick}
        >
          <p className="text-gray-400">
            {isLoading ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10 mx-auto mb-2 text-gray-400 animate-spin"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 4v16m8-8H4"
                />
              </svg>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10 mx-auto mb-2 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 8v8m4-4H8"
                  />
                </svg>
                Arrastra o selecciona algún archivo para continuar
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  className="hidden"
                  accept=".csv"
                />
              </>
            )}
          </p>
        </div>

        {/* Mostrar nombre del archivo si ha sido cargado */}
        {fileName && (
          <div className="mt-4 text-center">
            <p className="text-gray-700">
              <span className="font-bold">Archivo cargado:</span> {fileName}
            </p>
          </div>
        )}

        {/* Botones de cancelar y cerrar */}
        <div className="mt-6 text-right">
          <button
            onClick={() => {
              setFileName(null); // Limpiar el nombre del archivo
              setIsLoading(false);
              onClose();
            }}
            className="bg-red-500 text-white px-4 py-2 rounded-lg mr-2 hover:bg-red-700"
          >
            Cancelar
          </button>
          <button
            onClick={onClose}
            className="bg-gray-400 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalCargaCSV;
