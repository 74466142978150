import React from 'react';

const ResultsModalRFC = ({ isOpen, onClose, rfcData }) => {
  if (!isOpen || !rfcData) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-xl max-h-[80vh] overflow-y-auto">
        <h2 className="text-2xl font-bold text-blue-600 mb-4">Resultados de la Consulta de RFC</h2>
        <p className="text-gray-500 mb-6">Se encontraron los siguientes resultados:</p>

        <ul className="space-y-4">
          <li onClick={()=>console.log(rfcData)}><strong>Nombre:</strong> {rfcData["Nombre:"]}</li>
          <li><strong>Apellido Paterno:</strong> {rfcData["Apellido Paterno:"]}</li>
          <li><strong>Apellido Materno:</strong> {rfcData["Apellido Materno:"]}</li>
          <li><strong>CP:</strong> {rfcData["CP:"]}</li>
          <li><strong>CURP:</strong> {rfcData["CURP:"]}</li>
          <li><strong>Colonia:</strong> {rfcData["Colonia:"]}</li>
          <li><strong>Correo Electrónico:</strong> {rfcData["Correo electrónico:"]}</li>
          <li><strong>Entidad Federativa:</strong> {rfcData["Entidad Federativa:"]}</li>
          <li><strong>Fecha de Nacimiento:</strong> {rfcData["Fecha Nacimiento:"]}</li>
          <li><strong>Fecha de Inicio de Operaciones:</strong> {rfcData["Fecha de Inicio de operaciones:"]}</li>
          <li><strong>Fecha de Alta:</strong> {rfcData["Fecha de alta:"]}</li>
          <li><strong>Fecha del Último Cambio de Situación:</strong> {rfcData["Fecha del último cambio de situación:"]}</li>
          <li><strong>Municipio o Delegación:</strong> {rfcData["Municipio o delegación:"]}</li>
          <li><strong>Nombre de la Vialidad:</strong> {rfcData["Nombre de la vialidad:"]}</li>
          <li><strong>Número Exterior:</strong> {rfcData["Número exterior:"]}</li>
          <li><strong>Régimen:</strong> {rfcData["Régimen:"]}</li>
          <li><strong>Situación del Contribuyente:</strong> {rfcData["Situación del contribuyente:"]}</li>
          <li><strong>Tipo de Vialidad:</strong> {rfcData["Tipo de vialidad:"]}</li>
        </ul>

        <button 
          onClick={onClose} 
          className="mt-6 bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default ResultsModalRFC;
