import React from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Inicio from './components/Inicio';
import Extractor from './components/Extractor';
import Analizador from './components/Analizador';
import Consulta from './components/Consulta';
import Onboarding from './components/Onboarding';
import Login from './components/Login';

function App() {
  const location = useLocation(); // Hook para obtener la ruta actual

  return (
    <div className="flex flex-col lg:flex-row min-h-screen overflow-hidden">
      {/* Solo muestra el Sidebar si no estás en la ruta de login */}
      {location.pathname !== '/login' && <Sidebar   />}

      {/* Contenido principal */}
      <div className={`flex-1 ${location.pathname !== '/login' ? 'bg-saptibank-blue p-5 md:pl-0 min-h-full overflow-hidden' : ''}`}>
        <div className={`${location.pathname !== '/login' ? 'flex  bg-white p-6 rounded-lg shadow-md w-full max-w-8xl mx-auto h-full overflow-hidden' : ''}`}>
          <Routes>
            <Route path="/" element={<Navigate to="/inicio" />} /> {/* Redirige la raíz a Inicio */}
            <Route path="/login" element={<Login />} />
            <Route path="/inicio" element={<Inicio />} />
            <Route path="/extractor" element={<Extractor />} />
            <Route path="/analizador" element={<Analizador />} />
            <Route path="/consulta" element={<Consulta />} />
            <Route path="/onboarding" element={<Onboarding />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;

// import React from 'react';
// import { BrowserRouter as Router, Route, Routes,   } from 'react-router-dom';
// import Sidebar from './components/Sidebar';
// import Inicio from './components/Inicio';
// import Extractor from './components/Extractor';
// import Analizador from './components/Analizador';
// import Consulta from './components/Consulta';
// import Onboarding from './components/Onboarding';

// function App() {
//   return (
//     <Router>
//       <div className="flex flex-col lg:flex-row min-h-screen overflow-hidden">
//         <Sidebar className="w-full lg:w-1/4"  />

//         {/* Contenido principal */}
//         <div className="flex-1 bg-saptibank-blue p-5 md:pl-0 min-h-full overflow-hidden">
//         <div className="flex justify-center items-center bg-white p-6 rounded-lg shadow-md w-full max-w-8xl mx-auto h-full overflow-hidden">
//             <Routes>
//               <Route path="/" element={<Inicio />} />
//               <Route path="/extractor" element={<Extractor />} />
//               <Route path="/analizador" element={<Analizador />} />
//               <Route path="/consulta" element={<Consulta />} />
//               <Route path="/onboarding" element={<Onboarding />} />
//             </Routes>
//           </div>
//         </div>
//       </div>
//     </Router>
//   );
// }

// export default App;
