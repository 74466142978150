import React, { useState, useRef, useEffect } from 'react';
import { AiOutlineSend } from 'react-icons/ai';
import saptibank from '../img/Logo_Saptibank.png';
import { token } from '../utilities/onboarding/token';
import { sysPrompt } from '../utilities/onboarding/sysPrompt';
import { saptiva } from '../api/saptiva';

const Onboarding = () => {
  const [messages, setMessages] = useState([
    {
      sender: 'bot',
      text: '¡Hola! Qué gusto de tenerte por aquí, soy tu asistente y te ayudaré en tu solicitud con SAPTIBANK. Para continuar, responde este mensaje.',
      time: new Date(),
    },
  ]);
  const [userInput, setUserInput] = useState('');
  const [firstUserMessageSent, setFirstUserMessageSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Nuevo estado para el loader
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);
  const inputContainerRef = useRef(null);

  const formatTime = (date) => {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const getDisplayDate = (messageDate) => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const isToday = messageDate.toDateString() === today.toDateString();
    const isYesterday = messageDate.toDateString() === yesterday.toDateString();

    if (isToday) return 'HOY';
    if (isYesterday) return 'AYER';

    return messageDate.toLocaleDateString([], { day: '2-digit', month: 'short' });
  };

  const handleSendMessage = async () => {
    if (userInput.trim() !== '') {
      const currentTime = new Date();
      const newMessages = [...messages, { sender: 'user', text: userInput, time: currentTime }];
      setMessages(newMessages);
      setUserInput('');
      setIsLoading(true); // Activa el loader

      if (!firstUserMessageSent) {
        setFirstUserMessageSent(true);
      }
      resetTextareaHeight();

      try {
        const botResponse = await saptiva(userInput);
        const botTime = new Date();
        console.log(botResponse)
        if (botResponse.type === 'pdf') {
          // Si la respuesta es un PDF, agrega un mensaje especial
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              sender: 'bot',
              type: 'pdf',  // Tipo de mensaje
              file_name: botResponse.file_name,
              preview: botResponse.preview,  // En base64 para previsualización
              preview_image: botResponse.preview_image,
              download_link: botResponse.download_link,
              time: botTime,
            },
          ]);
        } else {
          // Si es solo texto, sigue como antes
          setMessages((prevMessages) => [
            ...prevMessages,
            { sender: 'bot', text: botResponse.response, time: botTime },
          ]);
        }
      } catch (error) {
        console.error("Error al obtener respuesta del bot:", error);
      } finally {
        setIsLoading(false); // Desactiva el loader después de recibir la respuesta
      }
    }
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
    adjustTextareaHeight();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    inputContainerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };


  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;

    if (textarea.scrollHeight > 80) {
      textarea.style.overflowY = 'auto';
      textarea.style.paddingLeft = '30px';
    } else {
      textarea.style.overflowY = 'hidden';
    }
  };

  const resetTextareaHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = '50px';
    textarea.style.overflowY = 'hidden';
    textarea.style.paddingLeft = 'inherit';
  };

  // Desplázate automáticamente al último mensaje sin importar el remitente
  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 100); // Agrega un ligero retraso para asegurar que los elementos se hayan renderizado
  }, [messages, isLoading]);

  return (
    <div className="flex flex-col w-full h-full border border-saptibank-blue-600 rounded-lg overflow-y-auto max-h-[90vh] bg-white">
      <div className="bg-[rgba(46,93,245,0.85)] text-white flex items-center justify-between p-4">
        <div className="flex items-center space-x-2">
          <img src={saptibank} alt="logo" className="w-13 h-12" />
          <span className="text-lg font-bold">saptibank</span>
        </div>
        <div className="text-sm">Asistente</div>
      </div>

      <div className="flex-1 p-4 overflow-y-auto bg-gray-100 max-h-[400px] md:max-h-[700px]">

        {firstUserMessageSent && (
          <div className="text-center text-gray-500 font-semibold mb-4">
            {getDisplayDate(messages.find((msg) => msg.sender === 'user').time)}
          </div>
        )}
        {messages.map((message, index) => (
          <div
            key={index}
            className={`mb-2 p-3 rounded-lg max-w-md break-words relative ${message.sender === 'user'
              ? 'ml-auto bg-saptibank-blue text-white text-left'
              : 'bg-[rgba(46,93,245,0.17)] text-black text-left'
              }`}
            style={{
              paddingBottom: '1.5rem',
              whiteSpace: 'pre-wrap',
            }}
          >
            {message.type === 'pdf' ? (
              <div className='w-full' style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '10px', marginBottom: '10px' }}>

                {/* Renderiza la imagen de la vista previa */}
                <img
                  src={`data:image/png;base64,${message.preview_image}`}
                  alt="PDF preview"
                  style={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: '8px 8px 0 0',
                    marginBottom: '5px',
                    objectFit: 'cover',
                  }}
                />
                <p><strong>{message.file_name}</strong></p>

                <a
                  href={`data:application/pdf;base64,${message.preview}`}
                  download={message.file_name}  // Asegura que el archivo se descargue con el nombre correcto
                  style={{ display: 'block', marginTop: '8px', color: '#007bff', textDecoration: 'underline' }}
                >
                  <button className='bg-saptibank-blue text-white w-full h-10' style={{ borderRadius: '8px' }}>
                    Descargar PDF
                  </button>
                </a>
              </div>
            ) : (
              message.text
            )}
            <span
              className={`absolute bottom-1 right-2 text-xs ${message.sender === 'user' ? 'text-white' : 'text-black'
                }`}
            >
              {formatTime(message.time)}
            </span>
          </div>
        ))}

        {isLoading && (
          <div className="flex flex-col items-center mt-4">
            <div className="loader border-t-4 border-blue-500 border-solid rounded-full w-8 h-8 animate-spin"></div>
            <span className="text-gray-500 text-sm mt-2">Cargando...</span>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      <div ref={inputContainerRef}  className="flex items-center p-3 border-t border-gray-300 bg-white">
        <textarea
          ref={textareaRef}
          rows="1"
          placeholder="Escribe tu mensaje..."
          value={userInput}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          disabled={isLoading} // Deshabilitar mientras carga
          className="flex-1 p-3 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 resize-none"
          style={{
            minHeight: '50px',
            height: '50px',
            maxHeight: '55px',
            overflowY: 'hidden',
            paddingBottom: '10px',
          }}
        />

        <button
          onClick={handleSendMessage}
          className="ml-3 p-2 bg-saptibank-blue text-white rounded-full hover:bg-blue-600"
          disabled={isLoading} // Deshabilitar el botón mientras carga
        >
          <AiOutlineSend className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default Onboarding;
