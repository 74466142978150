import React, { useState, useRef } from "react";
import { FaRegFileAlt } from "react-icons/fa";

const ModalCargarDocumentos = ({ isOpen, onClose, agregarArchivo }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); 
  const fileInputRef = useRef(null);

  const MAX_SIZE = 1024 * 1024; 
  const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg", "application/pdf"]; 

  const options = [
    { value: "INE", label: "INE" },
    { value: "CURP", label: "CURP" },
    { value: "CSF", label: "CONSTANCIA DE SITUACIÓN FISCAL" },
  ];

  if (!isOpen) return null;

  const validateFile = (file) => {
    if (!ALLOWED_TYPES.includes(file.type)) {
      setErrorMessage(
        `El tipo de archivo no es válido. Solo se permiten archivos PNG, JPEG, JPG, o PDF. Tipo actual: ${file.type}`
      );
      return false;
    }
    if (file.size > MAX_SIZE) {
      setErrorMessage(
        `El archivo debe tener un tamaño máximo de 1 MB. Tamaño actual: ${(file.size / 1024).toFixed(2)} KB`
      );
      return false;
    }
    setErrorMessage(""); // Limpiar mensaje de error si todo está bien
    return true;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && validateFile(file)) {
      setFileName(file.name);
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        agregarArchivo(file.name, selectedOption, file); 
        onClose(); 
      });
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg md:max-w-2xl mx-4"> 
      <h2 className="text-xl md:text-2xl font-bold text-saptibank-blue mb-4 text-left "> 
        Carga de Documentos
      </h2>

      <div className="flex flex-col md:flex-row items-start md:items-center mb-6"> {/* Alineación vertical en móviles */}
        <p className="text-gray-600 text-sm md:text-base">Selecciona el tipo de documento a cargar:</p>
        <div className="relative flex items-center w-full md:ml-4 mt-2 md:mt-0"> {/* Ajuste de margen */}
          <FaRegFileAlt className="absolute left-3 text-saptibank-blue" />
          <select
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
            className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full md:w-auto focus:outline-none focus:ring-2 focus:ring-saptibank-blue"
          >
            <option value="">Selecciona una opción</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <span className="text-gray-600 text-sm md:text-base">Puedes cargar un archivo para continuar, los tipos de archivos permitidos son: {ALLOWED_TYPES.join(", ")}</span>
      
      <div
        className={`border-2 ${selectedOption ? 'border-dashed border-saptibank-blue' : 'border-gray-300 bg-gray-100'} 
          p-4 md:p-6 rounded-lg text-center cursor-pointer ${!selectedOption && 'cursor-not-allowed opacity-50'} mt-2`}
        onClick={() => selectedOption && fileInputRef.current.click()}
      >
        <p className="text-gray-400 text-sm md:text-base">
          {isLoading ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 md:h-10 md:w-10 mx-auto mb-2 text-gray-400 animate-spin"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#2E5DF5"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
            </svg>
          ) : (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 md:h-10 md:w-10 mx-auto mb-2 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke={`${selectedOption ? '#2E5DF5' : 'currentColor'}`}
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v8m4-4H8m8-4H8m4 4H8" />
              </svg>
              <span>Arrastra el archivo o haz clic aquí</span>
            </>
          )}
        </p>
      </div>

      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        onChange={handleFileChange}
      />

      {errorMessage && <p className="text-red-500 mt-2 text-sm">{errorMessage}</p>}

      <div className="mt-8 flex justify-end">
        <button
          onClick={onClose}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg mr-2 w-full md:w-auto"
        >
          Cancelar
        </button>
      </div>
    </div>
  </div>
  );
};

export default ModalCargarDocumentos;
