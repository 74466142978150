import React, { useState } from 'react';
import { FaRegFileAlt, FaRegEye, } from "react-icons/fa";
import ModalCargarDocumentos from './ModalCargarDocumentos';
import { extractDocument } from '../api/extractor';
import DetalleExtractor from './DetalleExtractor';
import LoaderModal from './LoaderModal';


function Extractor() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [archivos, setArchivos] = useState([]);
  const [procesando, setProcesando] = useState(null);
  const [seeDetails, setSeeDetails] = useState(false); // Maneja el detalle
  const [selectedFile, setSelectedFile] = useState(null);
  const [extractedInfo, setExtractedInfo] = useState("");
  const [loadingDetails, setLoadingDetails] = useState(false); // Controla el loader al abrir detalles.

  const agregarArchivo = (nombreArchivo, tipoArchivo, documento) => {
    const id = archivos.length + 1;
    setArchivos([...archivos, { id, nombre: nombreArchivo, tipo: tipoArchivo, estatus: 'Listo para extraer', documento }]);
  };

  const procesarArchivo = async (archivoId) => {
    const archivosActualizados = archivos.map(archivo =>
      archivo.id === archivoId ? { ...archivo, estatus: 'Procesando' } :
        (archivo.estatus !== 'Error - Reintentar' && archivo.estatus !== 'Procesado') ? { ...archivo, estatus: 'En espera' } : archivo
    );
    setArchivos(archivosActualizados);
    setProcesando(archivoId);

    try {
      const archivo = archivos.find(archivo => archivo.id === archivoId);
      const response = await extractDocument(archivo.tipo, archivo.documento);
      console.log(response);

      const archivosProcesados = archivos.map(archivo =>
        archivo.id === archivoId ? { ...archivo, estatus: 'Procesado' } : archivo
      );
      setArchivos(archivosProcesados);
      setExtractedInfo(response)

    } catch (error) {
      console.error("Error al procesar el documento", error);

      const archivosError = archivos.map(archivo =>
        archivo.id === archivoId ? { ...archivo, estatus: 'Error - Reintentar' } : archivo
      );
      setArchivos(archivosError);
    }

    setProcesando(null);
  };

  const handleRetry = (archivoId) => {
    procesarArchivo(archivoId);
  };

  const handleOpenDetail = async (archivo) => {
    setLoadingDetails(true);


    await new Promise((resolve) => setTimeout(resolve, 500));

    setSelectedFile(archivo);
    setSeeDetails(true);
    setLoadingDetails(false);
  };

  const handleCloseDetail = () => {
    setSeeDetails(false);
    setSelectedFile(null);
    setExtractedInfo("")
  };

  return (
    <div className="p-5 w-full">
      {loadingDetails && <LoaderModal waitMessage={"La información se estará mostrando en breve"} isOpen={loadingDetails} message="Cargando detalles..." />}
      {seeDetails ? (
        <DetalleExtractor key={selectedFile?.id} archivo={selectedFile} handleCloseDetail={handleCloseDetail} extractedInfo={extractedInfo} />
      ) : (
        <>
          {/* Título y botón para pantallas grandes */}
          <div className="hidden md:flex justify-between items-center mb-6">
            <h1 className="text-3xl font-bold text-saptibank-blue">Documentos</h1>
            <button
              onClick={() => setIsModalOpen(true)}
              className="bg-saptibank-blue text-white py-2 px-4 rounded-lg flex items-center justify-center hover:bg-saptibank-blue transition duration-300 text-base md:text-xl w-full md:w-auto"
            >
              <FaRegFileAlt className="mr-2 md:mr-3" />
              <span>Cargar documentos</span>
            </button>
          </div>

          {/* Título y botón para pantallas pequeñas */}
          <div className="md:hidden flex flex-col items-center">
            <h1 className="text-3xl font-bold text-saptibank-blue">Documentos</h1>
            <button
              onClick={() => setIsModalOpen(true)}
              className="mt-6 mb-6 bg-saptibank-blue text-white py-2 px-4 rounded-lg flex items-center justify-center hover:bg-saptibank-blue transition duration-300 text-base md:text-xl w-[70vw]"
            >
              <FaRegFileAlt className="mr-2 md:mr-3" />
              <span>Cargar documentos</span>
            </button>
          </div>

          {/* Contenedor con scroll */}
          <div className="overflow-y-auto h-[78vh] rounded-lg">
            <div className="overflow-hidden rounded-lg  border-gray-300">
              {/* Diseño para pantallas grandes */}
              <table className="hidden md:table min-w-full bg-white">
                <thead>
                  <tr>
                    <th className="w-2/4 py-3 px-6 text-lg font-bold text-left text-slate-400 bg-gray-100">Archivo</th>
                    <th className="w-1/4 py-3 px-6 text-lg font-bold text-left text-slate-400 bg-gray-100">Tipo de Documento</th>
                    <th className="w-1/4 py-3 px-6 text-lg font-bold text-left text-slate-400 bg-gray-100">Estatus</th>
                  </tr>
                </thead>
                <tbody>
                  {archivos.length > 0 ? (
                    archivos.map((archivo) => (
                      <tr key={archivo.id}>
                        <td className="py-4 px-6 text-slate-700">{archivo.nombre}</td>
                        <td className="py-4 px-6 text-slate-700">{archivo.tipo}</td>
                        <td className="py-4 px-6 text-slate-700" style={{ minWidth: '200px' }}>
                          {archivo.estatus === 'Listo para extraer' && (
                            <button
                              className="bg-blue-500 text-white px-3 py-2 rounded-lg hover:bg-blue-700 w-full"
                              onClick={() => procesarArchivo(archivo.id)}
                              disabled={procesando !== null}
                            >
                              {archivo.estatus}
                            </button>
                          )}
                          {archivo.estatus === 'Procesando' && (
                            <span className="bg-yellow-400 text-white px-3 py-2 rounded-lg w-full inline-block text-center">{archivo.estatus}</span>
                          )}
                          {archivo.estatus === 'En espera' && (
                            <span className="bg-gray-400 text-white px-3 py-2 rounded-lg w-full inline-block text-center">{archivo.estatus}</span>
                          )}
                          {archivo.estatus === 'Procesado' && (
                            <div className="relative flex items-center justify-between">
                              <span className="bg-green-500 text-white px-3 py-2 rounded-lg w-full inline-block text-center">{archivo.estatus}</span>
                              <div className="relative group">
                                <FaRegEye
                                  className="ml-2 text-gray-500 cursor-pointer hover:text-[#2E5DF5] transition-all duration-300 transform hover:scale-125"
                                  size={20}
                                  onClick={() => handleOpenDetail(archivo)}
                                />

                                {/* Tooltip que aparece al hacer hover */}
                                <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:inline-block bg-gray-100 text-black text-xs px-2 py-1 rounded-md">
                                  Ver detalle
                                </span>
                              </div>

                            </div>
                          )}
                          {archivo.estatus === 'Error - Reintentar' && (
                            <button
                              className="bg-red-500 text-white px-3 py-2 rounded-lg hover:bg-red-700 w-full"
                              onClick={() => handleRetry(archivo.id)}
                              disabled={procesando !== null}
                            >
                              {archivo.estatus}
                            </button>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="py-4 px-6 text-slate-300">No hay archivos cargados</td>
                      <td className="py-4 px-6 text-slate-300">-</td>
                      <td className="py-4 px-6 text-slate-300">-</td>
                    </tr>
                  )}
                </tbody>
              </table>

              {/* Diseño para pantallas pequeñas */}
              <div className="md:hidden">
                {archivos.length > 0 ? (
                  archivos.map((archivo) => (
                    <div key={archivo.id} className="bg-white border-b-2  p-3 rounded-lg mb-2">
                      <div className="flex flex-col">
                        <span className="font-semibold text-slate-700">{archivo.nombre}</span>
                        <span className="text-slate-700">{archivo.tipo}</span>
                        <div className="mt-3">
                          {archivo.estatus === 'Listo para extraer' && (
                            <button
                              className="bg-blue-500 text-white px-3 py-2 rounded-lg hover:bg-blue-700 w-full"
                              onClick={() => procesarArchivo(archivo.id)}
                              disabled={procesando !== null}
                            >
                              {archivo.estatus}
                            </button>
                          )}
                          {archivo.estatus === 'Procesando' && (
                            <span className="bg-yellow-400 text-white px-3 py-2 rounded-lg w-full inline-block text-center">{archivo.estatus}</span>
                          )}
                          {archivo.estatus === 'En espera' && (
                            <span className="bg-gray-400 text-white px-3 py-2 rounded-lg w-full inline-block text-center">{archivo.estatus}</span>
                          )}
                          {archivo.estatus === 'Procesado' && (
                            <div className="relative flex items-center justify-between">
                              <span className="bg-green-500 text-white px-3 py-2 rounded-lg w-full inline-block text-center">{archivo.estatus}</span>
                              <div className="relative group">
                                <FaRegEye
                                  className="ml-2 text-gray-500 cursor-pointer hover:text-[#2E5DF5] transition-all duration-300 transform hover:scale-125"
                                  size={20}
                                  onClick={() => handleOpenDetail(archivo)}
                                />

                                {/* Tooltip que aparece al hacer hover */}
                                <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:inline-block bg-gray-100 text-black text-xs px-2 py-1 rounded-md">
                                  Ver detalle
                                </span>
                              </div>
                            </div>
                          )}
                          {archivo.estatus === 'Error - Reintentar' && (
                            <button
                              className="bg-red-500 text-white px-3 py-2 rounded-lg hover:bg-red-700 w-full"
                              onClick={() => handleRetry(archivo.id)}
                              disabled={procesando !== null}
                            >
                              {archivo.estatus}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-slate-300 text-center">No hay archivos cargados</p>
                )}
              </div>
            </div>
          </div>

          <ModalCargarDocumentos isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} agregarArchivo={agregarArchivo} />
        </>
      )}
    </div>
  );
}

export default Extractor;
