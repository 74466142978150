// LoaderModal.js
import React from 'react';
import { ClipLoader } from 'react-spinners';

const LoaderModal = ({ isOpen, message, waitMessage }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <h2 className="text-xl font-bold text-blue-600 mb-4">{message}</h2>
        <p className="text-gray-500 mb-6"> {waitMessage ?  waitMessage :"Denos un momento para consultar el portal relevante."}</p>
        <ClipLoader size={80} color="#007BFF" />
      </div>
    </div>
  );
};

export default LoaderModal;
