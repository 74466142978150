// API PARA EL CFDI
export const consultarCFDI = async (url) => {
    try {
      if (typeof url !== 'string' || !url.trim()) {
        throw new Error("La URL del CFDI es requerida y debe ser una cadena válida.");
      }
  
      const body = {
        operation: 'cfdi',
        url: url,
      };
  
      const response = await fetch('https://portalesrpa.vulcanics.mx/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
  
      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      // Verificar si hay un error en la respuesta de la API
      if (data.error) {
        throw new Error(`Error en la API: ${data.error}`);
      }
  
      console.log('Resultado de la API CFDI:', data); 
      return data;  
  
    } catch (error) {
      console.error('Error en la consulta de CFDI:', error.message);
      throw error;  
    }
  };
  
  // API PARA EL CURP - POST
export const consultarCurpPOST = async (curp) => {
    try {
      if (typeof curp !== 'string' || !curp.trim()) {
        throw new Error("El CURP es requerido y debe ser una cadena válida.");
      }
  
      const body = {
        operation: 'curp',
        curp: curp,
      };
  
      const response = await fetch('https://portalesrpa.vulcanics.mx/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
  
      if (!response.ok) {
        throw new Error(`Error en la solicitud POST: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      if (data.error) {
        throw new Error(`Error en la API POST: ${data.error}`);
      }
  
      console.log('ID recibido de la API POST CURP:', data.id); 
      return data.id;  // Devolvemos el ID para la siguiente solicitud
  
    } catch (error) {
      console.error('Error en la solicitud POST de CURP:', error.message);
      throw error;
    }
  };
  
 // API PARA EL CURP - GET
export const consultarCurpGET = async (curpId) => {
    try {
      if (!curpId || typeof curpId !== 'string') {
        throw new Error("El ID de CURP es requerido.");
      }
  
      console.log("CURP ID para la solicitud GET:", curpId);
  
      // Ahora enviamos la CURP en los headers en lugar de la URL
      const response = await fetch(`https://portalesrpa.vulcanics.mx/curp`, {
        method: 'GET',
        headers: {
          'curp': curpId, // Enviamos el CURP como header
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error en la solicitud GET: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      if (data.error) {
        throw new Error(`Error en la API GET: ${data.error}`);
      }
  
      return data;  // Retornamos los datos completos de la CURP
  
    } catch (error) {
      console.error('Error en la solicitud GET de CURP:', error.message);
      throw error;
    }
  };


  // API PARA LA VERIFICACIÓN DEL SAT
export const verificarSAT = async (url) => {
  try {
      if (typeof url !== 'string' || !url.trim()) {
          throw new Error("La URL del SAT es requerida y debe ser una cadena válida.");
      }

      const body = {
          operation: 'sat',
          url: url,
      };

      const response = await fetch('https://portalesrpa.vulcanics.mx/', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
      });

      if (!response.ok) {
          throw new Error(`Error en la solicitud: ${response.statusText}`);
      }

      const data = await response.json();

      if (data.error) {
          throw new Error(`Error en la API: ${data.error}`);
      }

      console.log('Resultado de la API SAT:', data);
      return data;

  } catch (error) {
      console.error('Error en la verificación de SAT:', error.message);
      throw error;
  }
};

  
  

  