import React, { useState, useEffect } from 'react';

const AjustesExtractor = ({ onAplicarFiltros, onClose, filtrosIniciales }) => {
  const [filtros, setFiltros] = useState(filtrosIniciales);

  useEffect(() => {
    setFiltros(filtrosIniciales); // Actualiza los filtros si cambian
    console.log("filtros iniciales",filtrosIniciales)
  }, [filtrosIniciales]);


  const handleToggle = (field) => {
    setFiltros((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleAplicarFiltros = () => {
    onAplicarFiltros(filtros); // Envía los filtros seleccionados a DetalleExtractor
  };

  return (
    <div className="min-h-screen bg-gray-100 ">
      <header className="bg-saptibank-blue p-4 flex items-center justify-between">
        <span className="text-white text-lg">Ajustes de Filtros</span>
        <button onClick={onClose} className="text-white">
          Cerrar
        </button>
      </header>

      <div className="max-w-4xl mx-auto my-auto bg-white  p-8 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold mb-6">Campos Obligatorios</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {Object.keys(filtros).map((key) => (
            <div key={key} className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={filtros[key]}
                onChange={() => handleToggle(key)}
                className="toggle-checkbox"
              />
              <label>{key.replace(/([A-Z])/g, ' $1')}</label>
            </div>
          ))}
        </div>

        <div className="flex justify-center mt-6">
          <button
            onClick={handleAplicarFiltros}
            className="bg-saptibank-blue text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition duration-300"
          >
            Aplicar Filtros
          </button>
        </div>
      </div>
    </div>
  );
};

export default AjustesExtractor;