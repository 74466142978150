import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Importa useNavigate
import logo from '../img/Logo_Saptibank_whte.png';
import { FaHome, FaFileCode, FaCoins, FaRegFile, FaIdBadge, FaSignOutAlt } from 'react-icons/fa';

function Sidebar() {
  const [selected, setSelected] = useState('Inicio');
  const navigate = useNavigate(); // Inicializa useNavigate

  // Función para manejar el cierre de sesión
  const handleLogout = () => {
    setSelected('Logout');
    navigate('/login'); // Redirige a la página de login
  };

  return (
    <div className="bg-saptibank-blue h-auto text-white flex flex-col overflow-hidden">
      <div className="p-6">
        {/* Contenedor del logo */}
        <div className="w-[383px] h-[113px] flex justify-center items-center overflow-hidden">
        <img src={logo} alt="saptibank Logo" className="max-w-full max-h-full" />
        </div>
        {/* Lista de opciones */}
        <ul className="flex-grow">
          <li className="mb-6">
            <Link
              to="/"
              className={`flex items-center text-lg p-3 rounded-lg ${
                selected === 'Inicio' ? 'bg-white text-saptibank-blue' : ''
              }`}
              onClick={() => setSelected('Inicio')}
            >
              <FaHome className="mr-2" /> Inicio
            </Link>
          </li>
          <li className="mb-6">
            <Link
              to="/extractor"
              className={`flex items-center text-lg p-3 rounded-lg whitespace-normal break-words ${
                selected === 'Extractor' ? 'bg-white text-saptibank-blue' : ''
              }`}
              onClick={() => setSelected('Extractor')}
            >
              <FaFileCode className="mr-2" /> Extractor de documentos
            </Link>
          </li>
          <li className="mb-6">
            <Link
              to="/analizador"
              className={`flex items-center text-lg p-3 rounded-lg whitespace-normal break-words ${
                selected === 'Analizador' ? 'bg-white text-saptibank-blue' : ''
              }`}
              onClick={() => setSelected('Analizador')}
            >
              <FaCoins className="mr-2" /> Analizador de ingresos
            </Link>
          </li>
          <li className="mb-6">
            <Link
              to="/consulta"
              className={`flex items-center text-lg p-3 rounded-lg whitespace-normal break-words ${
                selected === 'Consulta' ? 'bg-white text-saptibank-blue' : ''
              }`}
              onClick={() => setSelected('Consulta')}
            >
              <FaIdBadge className="mr-2" /> Consulta de portales
            </Link>
          </li>
          <li className="mb-6">
            <Link
              to="/onboarding"
              className={`flex items-center text-lg p-3 rounded-lg whitespace-normal break-words ${
                selected === 'Onboarding' ? 'bg-white text-saptibank-blue' : ''
              }`}
              onClick={() => setSelected('Onboarding')}
            >
              <FaRegFile className="mr-2" /> Onboarding
            </Link>
          </li>
        </ul>
      </div>
      <div className="p-6 mt-auto">
        <div
          className={`flex items-center text-lg p-3 rounded-lg whitespace-normal break-words cursor-pointer ${
            selected === 'Logout' ? 'bg-white text-saptibank-blue' : ''
          }`}
          onClick={handleLogout} // Llama a la función handleLogout
        >
          <FaSignOutAlt className="mr-2" /> Cerrar sesión
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
