import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import promo1 from '../img/promo1.png';
import promo2 from '../img/promo2.png';
import promo3 from '../img/promo3.png';

const Inicio = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const images = [
    { src: promo1, alt: 'Promoción 1' },
    { src: promo2, alt: 'Promoción 2' },
    { src: promo3, alt: 'Promoción 3' },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (current, next) => setActiveIndex(next),
    appendDots: dots => (
      <div className="absolute bottom-10 w-full flex justify-center"> {/* Ajustamos bottom */}
        <ul className="space-x-4 sm:space-x-6 md:space-x-8 lg:space-x-10">{dots}</ul>
      </div>
    ),
    customPaging: i => (
      <div
        className={`lg:mt-3 w-4 h-4 rounded-full transition-colors duration-300 
        ${i === activeIndex ? 'bg-saptibank-blue' : 'bg-gray-400'}`}  
      />
    )
  };

  return (
    <div className="flex justify-center items-center w-full">
      <div className="w-full max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto relative"> 
        <Slider {...settings}>
          {images.map((image, index) => (
            <div
              key={index}
              className="flex justify-center items-center h-[60vh] sm:h-[70vh] md:h-[75vh] lg:h-[80vh] xl:h-[85vh]" 
            >
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-full object-contain"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Inicio;
