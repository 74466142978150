import React, { useState } from 'react';
import LoaderModal from './LoaderModal';
import ResultsModalCFDI from './ResultsModalCFDI';
import ResultsModalCURP from './ResultsModalCURP';
import ResultsModalRFC from './ResultsModalRFC';
import { consultarCFDI, consultarCurpPOST, consultarCurpGET, verificarSAT } from '../api/consultar';

function Consulta() {
  const [isLoading, setIsLoading] = useState(false);
  const [isCurpResultsVisible, setIsCurpResultsVisible] = useState(false);
  const [currentLabel, setCurrentLabel] = useState('');
  const [curp, setCurp] = useState('');
  const [curpData, setCurpData] = useState(null);
  const [cfdiUrl, setCfdiUrl] = useState('');  
  const [cfdiData, setCfdiData] = useState(null); 
  const [rfcUrl, setRfcUrl] = useState(''); 
  const [rfcData, setRfcData] = useState(null); 
  const [constanciaCURP, setConstanciaCURP] = useState('');
  const [constanciaPassword, setConstanciaPassword] = useState('');
  const [ine, setIne] = useState('');
  const [isResultsVisible, setIsResultsVisible] = useState(false);
  const [isRfcResultsVisible, setIsRfcResultsVisible] = useState(false);



  
  const handleConsultCURP = async () => {
    try {
      const curpId = await consultarCurpPOST(curp);
      if (curpId) {
        const data = async () => { 
          const response =  await consultarCurpGET(curpId)
          if (response.status === "processing"){ 
            setIsLoading(true) 
            setCurrentLabel("Consultando CURP...");
          }
          else{
            console.log("Resultados CURP completados:", response);
            setCurpData(response);  
            setIsLoading(false);  
            setIsCurpResultsVisible(true);  
            setIsLoading(false) 
            clearInterval(intervalId)
          }
        }
        const intervalId = setInterval(async () => {
          data()
        },3000)

        intervalId();
      }
    } catch (error) {
      console.log(error)
    }
  }    

  // Función para cerrar el modal de CURP
  const closeResultsModalCURP = () => {
    setIsCurpResultsVisible(false);
    setCurrentLabel('');
    setCurpData(null);  
  };



// Constante para el CFDI

const handleConsultCFDI = async () => {
  console.log("CFDI URL ingresado:", cfdiUrl);
  setCurrentLabel('Consultando CFDI...');
  setIsLoading(true);

  try {
    const data = await consultarCFDI(cfdiUrl);
    console.log("Resultados CFDI:", data);

    // Guardamos los datos obtenidos de la API para pasarlos al modal
    setCfdiData(data);

    setIsLoading(false);
    setIsResultsVisible(true);
  } catch (error) {
    console.error("Error en la consulta de CFDI:", error.message);
    setIsLoading(false);
  }
};

const closeResultsModalCFDI = () => {
  setIsResultsVisible(false);
  setCurrentLabel('');
  setCfdiData(null); // Limpiamos los datos después de cerrar el modal
};

  // Función para la consulta de RFC/SAT
  const handleConsultSAT = async () => {
    console.log("RFC URL ingresado:", rfcUrl);
    setCurrentLabel('Consultando SAT...');
    setIsLoading(true);
  
    try {
      const data = await verificarSAT(rfcUrl);
      console.log("Resultados SAT:", data);
  
      // Guardamos los datos obtenidos de la API para pasarlos al modal
      setRfcData(data);
  
      setIsLoading(false);
      setIsRfcResultsVisible(true);  // Mostrar modal de resultados de RFC
    } catch (error) {
      console.error("Error en la consulta de SAT:", error.message);
      setIsLoading(false);
    }
  };

  const closeResultsModalSAT = () => {
    setIsRfcResultsVisible(false);
    setCurrentLabel('');
    setRfcData(null); // Limpiamos los datos después de cerrar el modal
  };
  
  

  return (
    <div className="p-6 flex flex-col w-full">
      <h1 className="text-3xl font-bold text-blue-700 mb-6">Consulta CURP</h1>

      {/* Formulario para ingresar la CURP */}
      <div className="border border-gray-300 p-10 rounded-lg flex-1 w-full flex flex-col justify-between space-y-6">
        <div className="flex w-full rounded-lg overflow-hidden shadow-lg border border-gray-300">
          <div className="bg-saptibank-purple text-white px-6 py-3 flex items-center w-40">
            Curp
          </div>
          <input
            type="text"
            className="flex-grow px-6 py-3 focus:outline-none border-l border-gray-300"
            placeholder="Ingresa tu CURP"
            value={curp}
            onChange={(e) => setCurp(e.target.value)}
          />
          <button
            className="bg-saptibank-purple text-white px-6 py-3 rounded-none hover:bg-saptibank-blue transition duration-300"
            onClick={handleConsultCURP}
          >
            Consultar
          </button>
        </div>

  {/* CFDI */}
  <div className="flex w-full rounded-lg overflow-hidden shadow-lg border border-gray-300">
                <div className="bg-saptibank-purple text-white px-6 py-3 flex items-center w-40">
                  CFDI
                </div>
                <input
                  type="text"
                  className="flex-grow px-6 py-3 focus:outline-none border-l border-gray-300"
                  placeholder="Ingresa tu CFDI"
                  value={cfdiUrl} 
                  onChange={(e) => setCfdiUrl(e.target.value)}
                />
                <button 
                  className="bg-saptibank-purple text-white px-6 py-3 rounded-none hover:bg-saptibank-blue transition duration-300"
                  onClick={handleConsultCFDI}
                >
                  Consultar
                </button>
              </div>
        
           {/* RFC */}
        {/* RFC para SAT */}
        <div className="flex w-full rounded-lg overflow-hidden shadow-lg border border-gray-300">
          <div className="bg-saptibank-purple text-white px-6 py-3 flex items-center w-40">
            RFC
          </div>
          <input
            type="text"
            className="flex-grow px-6 py-3 focus:outline-none border-l border-gray-300"
            placeholder="Ingresa la URL del RFC para SAT"
            value={rfcUrl}
            onChange={(e) => setRfcUrl(e.target.value)}
          />
          <button 
            className="bg-saptibank-purple text-white px-6 py-3 rounded-none hover:bg-saptibank-blue transition duration-300"
            onClick={handleConsultSAT}
          >
            Consultar
          </button>
        </div>

                {/* Constancia de situación fiscal */}
                <div className="flex w-full rounded-lg overflow-hidden shadow-lg border border-gray-300">
          <div className="bg-saptibank-purple text-white px-6 py-3 flex items-center w-48">
            Constancia de situación fiscal
          </div>
          
          <div className="flex flex-col w-full">
            <input
              type="text"
              className="px-6 py-3 focus:outline-none border-l border-gray-300"
              placeholder="Ingresa tu CURP"
              value={constanciaCURP}
              onChange={(e) => setConstanciaCURP(e.target.value)}
            />    
            <input
              type="text"
              className="px-6 py-3 focus:outline-none border-l border-t border-gray-300"
              placeholder="Ingresa tu contraseña"
              value={constanciaPassword}
              onChange={(e) => setConstanciaPassword(e.target.value)}
            />
          </div>

          <button 
            className="bg-saptibank-purple text-white px-6 py-3 rounded-none hover:bg-saptibank-blue transition duration-300"
            onClick={() => {
              setCurrentLabel('Consultando Constancia de situación fiscal...');
              console.log("Constancia CURP:", constanciaCURP, "Contraseña:", constanciaPassword); // Imprime los valores ingresados
            }}
          >
            Consultar
          </button>
        </div>

        {/* INE */}
        <div className="flex w-full rounded-lg overflow-hidden shadow-lg border border-gray-300">
          <div className="bg-saptibank-purple text-white px-6 py-3 flex items-center w-40">
            INE*
          </div>
          <input
            type="text"
            className="flex-grow px-6 py-3 focus:outline-none border-l border-gray-300"
            placeholder="Ingresa tu INE"
            value={ine}
            onChange={(e) => setIne(e.target.value)}
          />
          <button 
            className="bg-saptibank-purple text-white px-6 py-3 rounded-none hover:bg-saptibank-blue transition duration-300"
            onClick={() => {
              setCurrentLabel('Consultando INE...');
              console.log("INE ingresado:", ine); // Imprime el valor ingresado del INE
            }}
          >
            Consultar
          </button>
        </div>



      </div>

                      



      {/* Modal para mostrar la carga */}
      <LoaderModal isOpen={isLoading} message={currentLabel} />

      {/* Modal para mostrar los resultados de la CFDI */}
      <ResultsModalCFDI isOpen={isResultsVisible} onClose={closeResultsModalCFDI} cfdiData={cfdiData} />

      {/* Modal para mostrar los resultados de la CURP */}
      <ResultsModalCURP isOpen={isCurpResultsVisible} onClose={closeResultsModalCURP} curpData={curpData} />

      {/* Modal para mostrar los resultados de SAT */}
      <ResultsModalRFC isOpen={isRfcResultsVisible} onClose={closeResultsModalSAT} rfcData={rfcData} />
    </div>
  );
}

export default Consulta;
