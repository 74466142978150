import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; // Importamos SweetAlert2
import back from '../img/login.png';
import logo from '../img/Logo_Saptibank1.png';

const Login = () => {
  const navigate = useNavigate(); // Hook para redirigir
  const [username, setUsername] = useState(''); // Estado para usuario
  const [password, setPassword] = useState(''); // Estado para contraseña

  // Credenciales falsas
  const fakeCredentials = {
    username: 'admin',
    password: 'password123'
  };

  // Función para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault(); // Evita que la página se recargue

    // Validar credenciales
    if (username === fakeCredentials.username && password === fakeCredentials.password) {
      // Mostrar el loader
      Swal.fire({
        title: 'Iniciando sesión...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Mostrar animación de carga
        }
      });

      // Simular tiempo de carga (por ejemplo, 2 segundos)
      setTimeout(() => {
        Swal.close(); // Cerrar el loader
        navigate('/inicio'); // Redirige a la página principal
      }, 2000);
      
    } else {
      // Mostrar alerta de error con SweetAlert2
      Swal.fire({
        icon: 'error',
        title: 'Credenciales incorrectas',
        text: 'El usuario o la contraseña son incorrectos, por favor intenta nuevamente',
      });
    }
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-gray-800"
      style={{
        backgroundImage: `url(${back})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      {/* Contenedor del formulario */}
      <div className="relative z-10 bg-white p-10 rounded-lg shadow-lg w-full max-w-2xl mr-auto ml-10 h-[95vh]">
        <div className="text-center mb-6 mt-28">
          <img src={logo} alt="SaptibankLogo" className="mx-auto w-80 mb-1" />
          <h2 className="text-4xl font-bold text-saptibank-blue">Ingreso</h2>
        </div>

        {/* Formulario */}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-lg mb-2 font-bold" htmlFor="username">
              Usuario
            </label>
            <input
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-saptibank-blue"
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)} // Actualizar el estado de usuario
              placeholder="Ingresa tu usuario"
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 text-lg mb-2 font-bold" htmlFor="password">
              Contraseña
            </label>
            <input
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-saptibank-blue"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)} // Actualizar el estado de contraseña
              placeholder="Ingresa tu contraseña"
            />
          </div>

          <button
            type="submit"
            className="w-full bg-saptibank-blue text-white py-2 rounded-lg hover:bg-blue-700 transition duration-300 font-bold"
          >
            Iniciar sesión
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
