export const extractDocument = async (doc_type, document) => {
    try {
        if (!(document instanceof File)) {
            throw new Error("El campo 'document' debe ser un archivo.");
        }

        if (typeof doc_type !== 'string') {
            throw new Error("El campo 'doc_type' debe ser una cadena de texto.");
        }

        const formData = new FormData();
        formData.append('doc_type', doc_type);
        formData.append('document', document);

        const response = await fetch('https://xtractor.saptiva.vulcanics.mx', {
            method: 'POST',
            body: formData
        });

        if (!response.ok) {
            console.log(response)
            throw new Error(`Error en la llamada a la API: ${response.statusText} (${response.status})`);
        }

        const data = await response.json();

        if (data.error) {
            throw new Error(`Error en la respuesta de la API: ${data.error}`);
        }

        return data;

    } catch (error) {
        console.error('Error en extractDocument:', error.message);
        throw error; 
    }
};
