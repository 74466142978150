export const uploadCSV = async (uniqueID, file) => {
    try {
        if (!(file instanceof File)) {
            throw new Error("El campo 'file' debe ser un archivo.");
        }

        if (typeof uniqueID !== 'string') {
            throw new Error("El campo 'uniqueID' debe ser una cadena de texto.");
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('uniqueID', uniqueID);

        const response = await fetch('https://analizador.saptibank.vulcanics.mx/process_csv', {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`Error en la llamada a la API: ${response.statusText} (${response.status})`);
        }

        const data = await response.json();

        if (data.error) {
            throw new Error(`Error en la respuesta de la API: ${data.error}`);
        }

        return data;

    } catch (error) {
        console.error('Error en uploadCSV:', error.message);
        throw error;
    }
};
