import { useState, useEffect } from "react";
import AjustesExtractor from "./AjustesExtractor";

const DocumentoExtractor = ({ archivo, handleCloseDetail, extractedInfo }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [mostrarAjustes, setMostrarAjustes] = useState(false);
  const [filtros, setFiltros] = useState({});

  const filtrosPorTipo = {
    CSF: {
      curp: true,
      direccion1: true,
      direccion2: true,
      fechaEmision: true,
      fechaInicioOperaciones: true,
      municipio: true,
      nombreCompleto: true,
      rfc: true,
    },
    CURP: {
      curp: true,
      entidadFederativa: true,
      fechaExpedicion: true,
      fechaNacimiento: true,
      folioRegistro: true,
      nacionalidad: true,
      nombreCompleto: true,
      sexo: true,
    },
    INE: {
      curp: true,
      claveElector: true,
      fechaNacimiento: true,
      nombre: true,
      sexo: true,
      vigencia: true,
    },
  };

  useEffect(() => {
    if (archivo?.tipo && filtrosPorTipo[archivo.tipo]) {
      setFiltros(filtrosPorTipo[archivo.tipo]);
    }
  }, [archivo?.tipo]);


  const handleAbrirAjustes = () => setMostrarAjustes(true);
  const handleCerrarAjustes = () => setMostrarAjustes(false);

  const aplicarFiltros = (nuevosFiltros) => {
    setFiltros(nuevosFiltros);
    setMostrarAjustes(false);
  };

  const handleOpenFullscreen = () => {
    setIsFullscreen(true);
  };

  const handleCloseFullscreen = () => {
    setIsFullscreen(false);
  };
  const renderDocumento = () => {
    if (!archivo || !archivo.documento) return null;

    const { documento } = archivo;

    if (documento.type.startsWith('image/')) {
      const imageUrl = URL.createObjectURL(documento);
      return <img src={imageUrl} alt={archivo.nombre} className="w-full h-auto" />;
    } else if (documento.type === 'application/pdf') {
      const pdfUrl = URL.createObjectURL(documento);
      return (
        <iframe
          src={pdfUrl}
          title="Vista previa del documento"
          className="w-full h-[400px]"
          frameBorder="0"
        />
      );
    } else {
      return <p>Tipo de archivo no soportado.</p>;
    }
  };

  // Renderizar los campos en función del tipo de documento
  const renderFieldsByDocumentType = (tipo, data, filtros) => {
    const shouldRenderField = (field) => filtros[field] !== false; // Muestra solo si el filtro está activo

    switch (tipo) {
      case 'CSF':
        return (
          <>
            {shouldRenderField('curp') && <Field label="CURP" value={data.CURP} />}
            {shouldRenderField('direccion1') && <Field label="Dirección 1" value={data["DIRECCIÓN1"]} />}
            {shouldRenderField('direccion2') && <Field label="Dirección 2" value={data["DIRECCIÓN2"]} />}
            {shouldRenderField('fechaEmision') && <Field label="Fecha de Emisión" value={data["Fecha de Emisión"]} />}
            {shouldRenderField('fechaInicioOperaciones') && (
              <Field label="Fecha de inicio de operaciones" value={data["Fecha de inicio de operaciones"]} />
            )}
            {shouldRenderField('municipio') && <Field label="Municipio" value={data.Municipio} />}
            {shouldRenderField('nombreCompleto') && <Field label="Nombre Completo" value={data["Nombre Completo"]} />}
            {shouldRenderField('rfc') && <Field label="RFC" value={data.RFC} />}
          </>
        );
      case 'CURP':
        return (
          <>
            {shouldRenderField('curp') && <Field label="CURP" value={data.CURP} />}
            {shouldRenderField('entidadFederativa') && (
              <Field label="Entidad Federativa" value={data["Entidad Federativa"]} />
            )}
            {shouldRenderField('fechaExpedicion') && <Field label="Fecha de Expedición" value={data["Fecha de Expedición"]} />}
            {shouldRenderField('fechaNacimiento') && <Field label="Fecha de Nacimiento" value={data["Fecha de Nacimiento"]} />}
            {shouldRenderField('folioRegistro') && <Field label="Folio de Registro" value={data["Folio de Registro"]} />}
            {shouldRenderField('nacionalidad') && <Field label="Nacionalidad" value={data.Nacionalidad} />}
            {shouldRenderField('nombreCompleto') && <Field label="Nombre Completo" value={data["Nombre Completo"]} />}
            {shouldRenderField('sexo') && <Field label="Sexo" value={data.Sexo} />}
          </>
        );
      case 'INE':
        return (
          <>
            {shouldRenderField('curp') && <Field label="CURP" value={data.CURP} />}
            {shouldRenderField('claveElector') && <Field label="Clave de elector" value={data["Clave de elector"]} />}
            {shouldRenderField('fechaNacimiento') && <Field label="Fecha de nacimiento" value={data["Fecha de nacimiento"]} />}
            {shouldRenderField('nombre') && <Field label="Nombre" value={data.Nombre} />}
            {shouldRenderField('sexo') && <Field label="Sexo" value={data.Sexo} />}
            {shouldRenderField('vigencia') && <Field label="Vigencia" value={data.Vigencia} />}
          </>
        );
      default:
        return <p>No hay información disponible para este tipo de documento.</p>;
    }
  };


  // Componente para mostrar un campo individual
  const Field = ({ label, value }) => (
    <div className="mb-4">
      <label className="block text-gray-600">{label}</label>
      <input
        type="text"
        value={value || 'No disponible'}
        readOnly
        className="w-full p-2 border rounded-lg bg-white"
      />
    </div>
  );

  return mostrarAjustes ? (
    <AjustesExtractor
      filtrosIniciales={filtros}
      onAplicarFiltros={aplicarFiltros}
      onClose={handleCerrarAjustes}
    />

  ) : (
    <div className="min-h-screen bg-gray-100">
      {/* Encabezado */}
      <header className="bg-saptibank-blue p-4 flex items-center justify-between sticky top-0 z-50">
        <span className="text-white text-lg" onClick={handleAbrirAjustes}>Ajustes</span>
        <button onClick={handleCloseDetail} className="text-white">
          Cerrar
        </button>
      </header>

      {/* Contenedor principal */}
      <div className="max-w-7xl mx-auto mt-5 p-4 md:p-10 bg-white rounded-lg shadow-lg flex flex-col md:flex-row gap-6">


        {/* Columna izquierda: Vista previa del documento */}
        <div className="w-full md:w-1/2 flex items-center justify-center">
          <div
            className="border rounded-lg overflow-hidden w-full cursor-pointer"
            onClick={handleOpenFullscreen}
          >
            {renderDocumento()}
          </div>
        </div>

        {/* Columna derecha: Formulario de detalle del documento */}
        <div className="w-full md:w-1/2 bg-gray-100 p-4 md:p-8 rounded-lg">
          <h2 className="text-xl font-bold mb-4 text-center md:text-left">
            Tipo de Documento
          </h2>
          <input
            type="text"
            value={archivo.tipo}
            readOnly
            className="w-full mb-4 p-2 border rounded-lg bg-white"
          />

          {/* Información adicional basada en el tipo de documento */}
          <div className="space-y-4">
            {renderFieldsByDocumentType(archivo.tipo, extractedInfo, filtros)}
          </div>

          {/* Botón inferior */}
          <div className="mt-6 flex justify-center">
            <button className="bg-saptibank-blue text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition duration-300 w-full md:w-auto">
              Consultar Portales
            </button>
          </div>
        </div>
      </div>

      {/* Modal de pantalla completa para visualizar el documento */}
      {isFullscreen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-80 flex items-center justify-center">
          <button
            onClick={handleCloseFullscreen}
            className="absolute top-5 right-5 text-white text-3xl"
          >
            ✕
          </button>
          <div className="w-full h-full max-w-4xl max-h-[80vh] overflow-hidden">
            {renderDocumento()}
          </div>
        </div>
      )}
    </div>
  );

};

export default DocumentoExtractor;