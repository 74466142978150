import React from 'react';
import { FaRegFileAlt } from 'react-icons/fa';

const ResultsModalCURP = ({ isOpen, onClose, curpData }) => {
  if (!isOpen || !curpData) return null;

  // Función para descargar la imagen de la CURP
  const handleDownloadImage = async () => {
    try {
      const response = await fetch('https://portalesrpa.vulcanics.mx/curpPng', {
        method: 'GET',
        headers: {
          'curp': curpData["CURP"],  
        },
      });

      if (!response.ok) {
        throw new Error('Error al descargar la imagen.');
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `CURP_${curpData["CURP"]}.png`; 
      a.click();

      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar la imagen:", error.message);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-xl max-h-[none] overflow-y-auto">
        <h2 className="text-2xl font-bold text-blue-600 mb-4">Resultados de la Consulta CURP</h2>
        <p className="text-gray-500 mb-6">Se encontraron los siguientes resultados:</p>

        <div className="border border-gray-300 p-4 rounded-lg flex justify-between items-center mb-6">
          <div className="flex items-center">
            <FaRegFileAlt className="mr-2 text-gray-500" style={{ fontSize: '30px' }} />
            <div>
              <p className="text-lg font-bold">CURP</p>
            </div>
          </div>
          {/* Llamamos a handleDownloadImage cuando se presione el botón */}
          <button onClick={handleDownloadImage} className="bg-blue-600 text-white px-4 py-2 rounded-lg">
            Descargar
          </button>
        </div>

        {/* Mostrar los datos obtenidos de la API de CURP */}
        <ul className="space-y-4">
          <li><strong>Año Registro:</strong> {curpData["Año registro"]}</li>
          <li><strong>CURP:</strong> {curpData["CURP"]}</li>
          <li><strong>Nombre(s):</strong> {curpData["Nombre(s)"]}</li>
          <li><strong>Primer Apellido:</strong> {curpData["Primer apellido"]}</li>
          <li><strong>Segundo Apellido:</strong> {curpData["Segundo apellido"]}</li>
          <li><strong>Fecha de Nacimiento:</strong> {curpData["Fecha de nacimiento"]}</li>
          <li><strong>Entidad de Nacimiento:</strong> {curpData["Entidad de nacimiento"]}</li>
          <li><strong>Sexo:</strong> {curpData["Sexo"]}</li>
          <li><strong>Nacionalidad:</strong> {curpData["Nacionalidad"]}</li>
          <li><strong>Documento probatorio:</strong> {curpData["Documento probatorio"]}</li>
          <li><strong>Número de Acta:</strong> {curpData["Número de acta"]}</li>
        </ul>

        <button 
          onClick={onClose} 
          className="mt-6 bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default ResultsModalCURP;
